@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Oswald:wght@400;700&display=swap');

@import "module/reset";
@import "module/module";

//USE ASSETS
@import "plugin/swiper";

/* BASE COLOR
------------------------------------------------------------*/
$normal: #070717;
$white: #FFF;
$black: #070717;
$yellow: #e4b205;
$link: $yellow;
$border: #e8e8e8;
$gray: #f5f5f6;

$ease: cubic-bezier(0.85, 0, 0.15, 1);



/* SITE SETTING
------------------------------------------------------------*/
$width: 1120px;
$tab: $width - 1px;
$phone: 640px;

$pc-head:108px;
$sp-head:60px;

@mixin awesame(){
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

:root{
	font-size: 14px;
	@media screen and (max-width: $phone){
		font-size: 13px;	
	}
}

@media screen and (min-width: $width){
	@include default(
		$width, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		40px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $tab){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        30px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width: $phone){
	@include default(
		auto, //サイト幅
		1rem, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        15px, //パディング
		#FFF //ボディ背景
	);
	input,
	select,
	textarea{
		-webkit-appearance: none;
	}
}

@mixin fontsize($w: 32px, $m: 22){
    font-size: calc(#{$w} + #{$m} * (100vw - 450px)/ 900);
}

@import "common/header";
@import "common/footer";
@import "page/index";
@import "page/school";
@import "page/online";


#site-wrapper,#site-footer{
	.secondary{
		padding: 108px 0 0;
		background: $normal;
		@media screen and (max-width: $phone){
			padding: 32px 0 0;	
		}
	}
}

.site-module{
	&.wrap{
		padding: 88px 0;
		@media screen and (max-width: $tab){
			padding: 48px 30px;	
		}
		@media screen and (max-width: $phone){
			padding: 36px 15px;	
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
		&.gray{
			background: $gray;
		}
	}
	h2,
	h3{
		&.large{
			font-size: 38px;
			font-weight: 700;
			line-height: 1.6;
			margin: 0 0 40px;
			text-align: center;
			@media screen and (max-width: $phone){
				font-size: 1.64rem;
				margin: 0 0 16px;
			}
			&:before{
				content: attr(data-eng);
				@include oswald();
				font-size: 11px;
				color: $yellow;
				display: block;
				line-height: 1;
				@media screen and (max-width: $phone){
					margin: 0 0 3px;	
				}
			}
		}
	}
	h3,
	h4{
        &.borders{
            border-bottom: $border 1px solid;
            position: relative;
            padding: 12px;
            padding-left: 20px;
            font-size: 1.56rem;
            line-height: 1;
            font-weight: 700;
            margin: 0 0 24px;
			@media screen and (max-width: $phone){
				font-size: 1.32rem;
				margin: 0 0 16px;
			}
            &:after{
                content: '';
                width: 5px;
                height: 70%;
                background: $yellow;
                border-radius: 2px;
                display: block;
                position: absolute;
                top: 15%;
                left: 0;
            }
        }
	}
	.btnarea{
		margin: 32px 0 0;
		@media screen and (max-width: $phone){
			margin: 24px 0 0;	
		}
		&.mtl{
			margin-top: 56px;
			@media screen and (max-width: $phone){
				margin-top: 32px;	
			}
		}
		&.center{
			text-align: center;
		}
		a{
			min-width: 300px;
			text-align: center;
            border: $yellow 1px solid;
            padding: 12px 56px;
            display: inline-block;
            position: relative;
			font-size: 1.2em;
            transition: color 300ms $ease, background 300ms $ease;
			@media screen and (max-width: $phone){
				min-width: auto;
				display: block;
				padding: 12px 0;
			}
            &:after{
                content: "\f061";
                @include awesame();
                font-size: .8rem;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
			&.large{
				font-size: 1.7rem;
				line-height: 1;
				font-weight: 700;
				border-color: $black;
				background: $black;
				padding: 32px 190px;
				color: #FFF;
				transition: transform 120ms $ease;
				@media screen and (max-width: $tab){
					padding: 32px 56px 32px 24px;
					display: block;
					line-height: 1.44;
				}
				@media screen and (max-width: $phone){
					font-size: 1.2rem;
					padding: 24px 56px 24px 24px;
				}
				&:before{
					content: '';
					width: 100%;
					height: 100%;
					border: $black 1px solid;
					position: absolute;
					top: 8px;
					left: 8px;
					pointer-events: none;
					transition: top 120ms $ease, left 120ms $ease, opacity 120ms $ease;
				}
				&:after{
					font-size: 1.4rem;
					right: 24px;
				}
				&:hover{
					background: $black;
					transform: translate(3px, 3px);
					&:before{
						top: 0;
						left: 0;
					}
				}
			}
			&.yel{
				border-color: $yellow;
				color: $yellow;
				background: none;
				&:before{
					border-color: $yellow;
					border-top: 0;
					border-left: 0;
				}
				&:hover{
					color: $yellow;
					background: none;
					&:before{
						opacity: 0;
					}
				}
			}
            &:hover{
                color: #FFF;
				background: $yellow;
            }
		}
	}
}

#pageup{
	position: fixed;
	right: 24px;
	bottom: 24px;
	background: $yellow;
	z-index: 1000;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	@include flex();
	align-items: center;
	justify-content: center;
	transform: scale(.8);
	opacity: 0;
	pointer-events: none;
	transition: opacity 600ms $ease, transform 600ms $ease, box-shadow 600ms linear;
	@media screen and (max-width: $phone){
		right: 15px;
		bottom: 15px;
		width: 36px;
		height: 36px;
	}
	> i{
		font-size: 20px;
		color: #FFF;
		@media screen and (max-width: $phone){
			font-size: 16px;	
		}
	}
	&.active{
		opacity: 1;
		transform: scale(1);
		box-shadow: 0 0 24px rgba(#000,.12);
		pointer-events: auto;
	}
}

.scroll-anime{
	transform: translateY(32px) scale(1.02);
	opacity: 0;
	transition: transform 600ms cubic-bezier(0.37, 0, 0.63, 1), opacity 600ms cubic-bezier(0.37, 0, 0.63, 1);
	&._active{
		transform: translate(0) scale(1);
		opacity: 1;
	}
}

#overlay{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	background: rgba($border, .96);
	visibility: hidden;
	&.active{
		visibility: visible;
	}
	.box{
		width: 640px;
		height: auto;
		max-height: 80vh;
		overflow: auto;
		padding: 32px;
		padding-top: 48px;
		background: #FFF;
		border-radius: 6px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0 0 32px rgba($black,.08);
		@media screen and (max-width: $phone){
			width: calc(100vw - 30px);	
			max-height: calc(100% - 30px);
			padding: 20px;
		}
		&__close{
			$size: 32px;
			width: $size;
			height: $size;
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
			&:before,
			&:after{
				content: '';
				width: 100%;
				height: 1px;
				background: $normal;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(45deg);
				transition: transform 200ms $ease;
			}
			&:after{
				transform: translate(-50%, -50%) rotate(-45deg);
			}
			&:hover{
				&:before,
				&:after{
					transform: translate(-50%, -50%) rotate(0);
				}
			}
		}
		&__title{
			font-size: 1.8rem;
			font-weight: 700;
			text-align: center;
			margin: 0 0 40px;
			@media screen and (max-width: $phone){
				margin: 0 0 24px;	
			}
		}
		&__list{
			@include flex();
			align-items: center;
			margin: 0 0 24px;
			padding: 0 0 24px;
			border-bottom: $border 1px dashed;
			@media screen and (max-width: $phone){
				margin: 0 0 15px;
				padding: 0 0 15px;
				display: block;
			}
			dt{
				font-weight: 700;
				font-size: 1.04rem;
				width: 180px;
				position: relative;
				@media screen and (max-width: $phone){
					width: auto;	
				}
				&:after{
					content: attr(data-hiss);
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					font-size: .88rem;
					background: $yellow;
					border-radius: 2px;
					padding: 3px 8px;
					color: #FFF;
				}
				&.nini{
					&:after{
						content: '任意';
						background: $gray;
						color: $normal;
					}
				}
			}
			dd{
				width: calc(100% - 180px);
				padding: 0 0 0 32px;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 8px 0 0;
				}
				input,
				select,
				textarea{
					@include font();
					font-size: 1.04rem;
					border: $border 2px solid;
					padding: 5px 8px;
					border-radius: 3px;
					width: 100%;
					outline: none;
					transition: box-shadow 200ms $ease, border 200ms $ease;
					&:focus{
						box-shadow: 0 0 32px rgba(#000,.12);
						border-color: #FFF;
					}
				}
				select{
					cursor: pointer;
				}
				textarea{
					min-width: 100%;
					max-width: 100%;
					min-height: 120px;
					max-height: 120px;
				}
			}
		}
		&__privacy{
			background: $gray;
			padding: 24px;
			overflow: auto;
			height: 148px;
			@media screen and (max-width: $phone){
				padding: 15px;	
			}
			dl{
				margin: 0 0 24px;
				&:last-of-type{
					margin: 0;
				}
				dt{
					font-weight: 700;
				}
			}
		}
		&__send{
			text-align: center;
			margin: 24px 0 0;
			@media screen and (max-width: $phone){
				margin: 24px 0 12px;	
			}
			input{
				@include font();
				font-size: 1.44rem;
				border: $yellow 2px solid;
				padding: 9px 64px;
				font-weight: 700;
				border-radius: 999px;
				color: #FFF;
				background: $yellow;
				cursor: pointer;
				&:hover{
					background: none;
					color: $yellow;
				}
			}
		}
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 800ms $ease;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.04);
}

body.second{
	background: #EEE;
	.alerting{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 640px;
		background: #FFF;
		padding: 48px 32px;
		border-radius: 4px;
		box-shadow: 0 0 32px rgba(#000,.08), 8px 8px 16px rgba(#000,.06);
		text-align: center;
		@media screen and (max-width: $phone){
			width: calc(100vw - 30px);
			height: calc(100% - 30px);
		}
		h1{
			font-size: 2rem;
			font-weight: 700;
			margin: 0 0 12px;
		}
	}
}
