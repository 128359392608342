$online-color: #f84c48;
#site-header{
	&.online-header{
		height: auto;
		padding: 24px 48px;
		position: absolute;
		@media screen and (max-width: $tab){
			padding: 16px 40px;
		}
		@media screen and (max-width: $phone){
			padding: 12px 15px;
			padding-left: 24px;
		}
	}
	.online-header{
		&__return{
			@media screen and (max-width: $phone){
				margin: 8px 0 0;
				font-size: .88rem;
			}
			a{
				color: $normal;
				&:hover{
					text-decoration: underline;
				}
				svg{
					display: inline-block;
					transform: rotateY(-180deg);
					margin: 0 8px 0 0;
				}
			}
		}
		&__contact{
			font-size: 1.08rem;
			font-weight: 400;
			position: absolute;
			top: 24px;
			right: 48px;
			background: $normal;
			color: #FFF;
			padding: 12px 48px;
			border-radius: 999px;
			@media screen and (max-width: $tab){
				right: 40px;
			}
			@media screen and (max-width: $phone){
				right: 15px;
				top: 12px;
				font-size: 1rem;
				padding: 9px 24px;
			}
			&:hover{
				background: $online-color;
			}
			i{
				display: inline-block;
				margin: 0 8px 0 0;
			}
		}
	}
}
.site-online{
	.firstvirw{
		background: #efefef;
		position: relative;
		@media screen and (max-width: $phone){
			padding: 60px 0 0;
		}
		&:before{
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 16px 16px 0 0;
			border-color: $online-color transparent transparent transparent;
			position: absolute;
			top: 12px;
			left: 12px;
			z-index: 2;
			@media screen and (max-width: $tab){
				top: 8px;
				left: 8px;
			}
			@media screen and (max-width: $phone){
				top: 4px;
				left: 4px;
			}
		}
		img{
			width: 100%;
		}
		&__title{
			position: absolute;
			top: 50%;
			left: 8vw;
			transform: translateY(-50%);
			line-height: 1.4;
			z-index: 2;
			@media screen and (max-width: $tab){
				left: 5.6vw;
			}
			@media screen and (max-width: $phone){
				top: calc(50% + 24px);
			}
			h1{
				font-size: 3.4rem;
				font-weight: 700;
				margin: 12px 0 0;
				@media screen and (max-width: $tab){
					font-size: 2.4rem;
					margin: 0;
				}
				@media screen and (max-width: $phone){
					font-size: 1.8rem;
				}
			}
			&__eng{
				font-size: 1.1rem;
				font-weight: 700;
				margin: 12px 0 0;
				@media screen and (max-width: $tab){
					font-size: 1rem;
				}
				@media screen and (max-width: $phone){
					font-size: .9rem;
				}
			}
		}
	}
	.site-module{
		h2.large{
			&:before{
				color: $online-color;
				letter-spacing: .12em;
			}
		}
		h3.borders{
			font-size: 1.66rem;
			border-bottom: rgba(#000,.12) 1px solid;
			&:after{
				background: $online-color;
			}
		}
	}
	.pricelist{
		&__item{
			margin: 0 0 32px;
			padding: 32px;
			background: $gray;
			@media screen and (max-width: $phone){
				padding: 15px;
				padding-top: 20px;
			}
			h3{
				@include flex();
				align-items: center;
				font-size: 1.64rem;
				line-height: 1.4;
				font-weight: 700;
				@media screen and (max-width: $phone){
					font-size: 1.24rem;
				}
				small{
					font-size: 1.12rem;
					@media screen and (max-width: $phone){
						font-size: .88rem;
					}
					> span{
						font-size: 1.64rem;
						color: $online-color;
						display: inline-block;
						margin: 0 0 0 8px;
						@media screen and (max-width: $phone){
							font-size: 1.24rem;
						}
					}
				}
			}
		}
    &__box{
      margin: 20px 0 0;
      padding: 16px 0 0;
      border-top: #FFF 2px solid;
			&__item{
				background: #FFF;
				padding: 12px 20px;
				box-shadow: 0 0 16px rgba(#000,.04);
				margin: 0 0 12px;
				font-size: 1.24rem;
				@media screen and (max-width: $phone){
					font-size: 1rem;
					position: relative;
					padding: 12px 15px;
					padding-left: 40px;
				}
				i{
					display: inline-block;
					margin: 0 8px 0 0;
					color: $online-color;
					@media screen and (max-width: $phone){
						position: absolute;
						top: 17px;
						left: 15px;
						margin: 0;
						font-size: 1.12rem;
					}
				}
				strong{
					display: inline-block;
					margin: 0 0 0 12px;
					span{
						color: $online-color;
					}
				}
				&:last-of-type{
					margin: 0
				}
			}
    }
	}
	.flow{
		@include flex();
		@media screen and (max-width: $phone){
			display: block;
		}
		li{
			list-style: none;
			width: calc(33.3% - 16px);
			background: #FFF;
			padding: 12px 16px;
			text-align: center;
			font-size: 1.24rem;
			font-weight: 700;
      color: $normal;
			border: $normal 2px solid;
			position: relative;
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 0 24px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			@for $i from 1 through 3{
				&:nth-child(#{$i}){
					&:before{
						content: 'STEP.#{$i}';
					}
				}
			}
			&:before{
				position: absolute;
				top: 0;
				left: 16px;
				background: $online-color;
				color: #FFF;
				font-weight: 400;
				transform: translateY(-50%);
				font-size: .8rem;
				padding: 1px 16px 2px;
				border-radius: 999px;
			}
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 12px 0 12px 16px;
				border-color: transparent transparent transparent $normal;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translate(100%, -50%);
				@media screen and (max-width: $phone){
					border-width: 16px 12px 0 12px;
					border-color: $normal transparent transparent transparent;
					right: auto;
					left: 50%;
					top: auto;
					bottom: 0;
					transform: translate(-50%, 100%);
				}
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
			i{
				display: inline-block;
				margin: 0 6px 0 0;

			}
		}
	}
	.profile{
		@include flex();
		@media screen and (max-width: $phone){
			display: block;
		}
		&__pic{
			width: 300px;
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 auto;
				background: darken($gray, 4%);
				padding: 0 24%;
			}
			img{
				width: 100%;
			}
		}
		&__data{
			width: calc(100% - 300px);
			padding: 0 0 0 40px;
			@media screen and (max-width: $phone){
				padding: 24px 0 0;
				width: auto;
			}
			dl{
				margin: 0 0 40px;
				&:last-of-type{
					margin: 0;
				}
				dt{
					border-bottom: $normal 1px solid;
					margin: 0 0 16px;
					strong{
						display: inline-block;
						background: $normal;
						color: #FFF;
						font-weight: 400;
						vertical-align: bottom;
						padding: 3px 24px;
					}
				}
				dd{
					&:first-of-type{
						
					}
				}
				&.history{
					@include flex();
					justify-content: flex-start;
					dt{
						width: 100%;
					}
					dd{
						font-size: 1.12rem;
						@media screen and (max-width: $phone){
							font-size: 1rem;
						}
						&:after{
							content: '→';
							display: inline-block;
							margin: 0 12px;
						}
						&:last-of-type{
							&:after{
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.photolist__grid{
		.grid-item{
			width: 33.3%;
			padding: 8px;
			@media screen and (max-width: $phone){
				width: 50%;
				padding: 5px;
			}
			img{
				border-radius: 8px;
				box-shadow: 0 0 12px rgba(#000,.08);
			}
		}
	}

  .btn{
		text-align: center;
		margin: 64px 0 0;
		@media screen and (max-width: $tab){
			margin: 48px 0 0;
		}
		a{
			background: $normal;
			color: #FFF;
			padding: 12px 56px;
			display: inline-block;
			border-radius: 999px;
			vertical-align: bottom;
			font-size: 1.44rem;
			i{
				display: inline-block;
				margin: 0 8px 0 0;
			}
			&:hover{
				background: $online-color;
			}
		}
  }
}