.site-school{
	$orange: #d73408;
	position: relative;
	&.secondary{
		background: $orange !important;
	}
	&:before{
		content: '';
		width: 100%;
		height: 80vh;
		background: url(/images/school/table_bg.jpg) no-repeat right top / contain;
		position: absolute;
		opacity: .56;
		top: 0;
		right: 0;
		z-index: 0;
	}
	.title{
		line-height: 1.56;
		color: $border;
		padding: 48px 0 112px;
		border-bottom: rgba(#FFF,.16) 1px solid;
		margin: 0 6.4vw;
		position: relative;
		z-index: 2;
		@media screen and (max-width: $phone){
			padding: 32px 16px 36px;
			margin: 0;
		}
		h1{
			font-size: 4.4rem;
			@media screen and (max-width: $phone){
				font-size: 2rem;	
			}
		}
		p{
			font-size: 1.44rem;
			@media screen and (max-width: $phone){
				font-size: 1rem;	
			}
		}
	}
	.teacher{
		padding: 88px 0;
		color: $border;
		@media screen and (max-width: $tab){
			padding: 80px 40px;	
		}
		@media screen and (max-width: $phone){
			padding: 32px 16px;	
		}
		&__list{
			@include flex();
			position: relative;
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: calc(50% - 32px);
				padding: 0 0 100px;
				position: relative;
				@media screen and (max-width: $tab){
					width: calc(50% - 20px);
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 0;
					margin: 0 0 40px;
					&:last-child{
						margin: 0;
					}
				}
				figure{
					img{
						width: 100%;
						@media screen and (max-width: $phone){
							border-radius: 3px;	
						}
					}
				}
				dl{
					margin: 32px 0 0;
					@include flex();
					position: relative;
					@media screen and (max-width: $phone){
						margin: 24px 0 0;
						display: block;
					}
					dt{
						width: 160px;
						font-size: 1.56rem;
						font-weight: 700;
						line-height: 1.56;
						@media screen and (max-width: $phone){
							width: auto;	
						}
						&:before{
							content: attr(data-eng);
							color: $yellow;
							font-size: .88rem;
							font-weight: 400;
							text-indent: .08em;
							display: block;
						}
					}
					dd{
						width: calc(100% - 160px);
						padding: 0 0 0 48px;
						@media screen and (max-width: $phone){
							width: auto;
							padding: 12px 0 0;
						}
					}
				}
				.btnarea{
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 0;
					margin: 0;
					@media screen and (max-width: $phone){
						position: relative;
						margin: 24px 0 0;
					}
					a{
						display: block;
						border: none;
						background: darken($orange, 20%);
						color: #FFF;
						&:hover{
							background: darken($orange, 30%);
						}
					}
                }
			}
		}
	}
	.schedule{
		background: $gray;
		&__move{
			padding: 0 0 0 40px;
			position: relative;
			margin: 0 0 24px;
			svg{
				position: absolute;
				top: 2px;
				left: 0;
				width: 16px;
				path{
					fill: $normal;
				}
				animation: slidemove 1200ms $ease alternate infinite;
				@keyframes slidemove{
					0%{
						transform: translateX(0);
					}
					100%{
						transform: translateX(12px);
					}
				}
			}
		}
		.borders{
			small{
				display: inline-block;
				font-size: .96rem;
				margin: 0 0 0 16px;
				@media screen and (max-width: $phone){
					display: block;
					margin: 8px 0 0;
				}
			}
		}
		&__table{
			width: 100%;
			overflow: auto;
			table{
				width: 130%;
				border-collapse: collapse;
				border: $border 1px solid;
				background: #FFF;
				@media screen and (max-width: $phone){
					width: 300%;	
				}
				tbody,
				thead{
					tr{
						th,td{
							font-size: .92rem;
							width: calc(100% / 8);
							border: $border 1px solid;
							padding: 24px 15px;
							text-align: center;
							vertical-align: middle;
							@media screen and (max-width: $phone){
								font-size: .88rem;	
							}
						}
					}
				}
				thead{
					background: $gray;
					font-weight: 700;
				}
			}
		}
	}
	
	.price{
		background: $gray;
		position: relative;
		&:before{
			content: '';
			width: 100%;
			height: 320px;
			background: url(/images/index/case_bg_2x.jpg) no-repeat center center / cover;
			position: absolute;
			opacity: .24;
			left: 0;
			bottom: 0;
			z-index: 1;
		}
		&__list{
			@include flex();
			padding: 0 0 40px;
			border-bottom: $border 1px solid;
			display: block;
			@media screen and (max-width: $phone){
				display: block;
				padding: 0 0 32px;
			}
			li{
				height: auto;
				background: #FFF;
				border-radius: 2px;
				padding: 24px;
				box-shadow: 0 0 24px rgba($normal,.05);
				position: relative;
				border-radius: 1px;
				overflow: hidden;
				@media screen and (max-width: $phone){
					width: auto;
					padding: 16px;
					margin: 0 0 24px;
					&:last-child{
						margin: 0;
					}
				}
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 20px 20px 0 0;
					border-color: $yellow transparent transparent transparent;
					position: absolute;
					top: 0;
					left: 0;
				}
				&:last-child{
					margin: 0;
				}
				dl{
					font-size: 1.12rem;
					@include flex();
					align-items: center;
					@media screen and (max-width: $phone){
						font-size: 1rem;	
					}
					dt{
						font-size: 1.32rem;
						font-weight: 700;
						@media screen and (max-width: $phone){
							font-size: 1.2rem;
							br{
								display: none;
							}
						}
					}
				}
				&.flex{
					@include flex();
					align-items: center;
					@media screen and (max-width: $phone){
						display: block;	
					}
					dl{
						width: 200px;
						@media screen and (max-width: $phone){
							width: auto;	
						}
					}
					p{
						width: calc(100% - 200px);
						padding: 0 0 0 24px;
						@media screen and (max-width: $phone){
							width: auto;
							padding: 12px 0 0;
						}
					}
				}
			}
		}
	}
	.maps{
		margin: 40px 0 0;
		background: #FFF;
		padding: 32px;
		text-align: center;
		box-shadow: 0 0 24px rgba($normal,.05);
		@media screen and (max-width: $phone){
			margin: 24px 0 0;
			padding: 16px;
		}
		h4{
			font-size: 1.24rem;
			font-weight: 700;
			text-align: center;
			margin: 0 0 32px;
			@media screen and (max-width: $phone){
				font-size: 1rem;
				margin: 0 0 12px;
			}
		}
		&__pics{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;
			}
			li{
				width: 32%;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 12px;
					&:last-child{
						margin: 0;
					}
				}
				img{
					width: 100%;
				}
			}
		}
	}
	.line{
		margin: 80px 0 0;
		@include flex();
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 2;
		@media screen and (max-width: $phone){
			margin: 32px 0 0;	
		}
		figure{
			width: 240px;
			padding: 20px;
			background: #FFF;
			text-align: center;
			border-radius: 8px;
			box-shadow: 0 0 24px rgba($normal,.05);
			figcaption{
				padding: 20px 0 0;
				line-height: 1.6;
				a{
					color: #06c755;
				}
			}
		}
	}
}