.site-index{
	.firstview{
		background: $black;
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		min-height: 760px;
		overflow: hidden;
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .64;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
		@media screen and (max-width: $tab){
			min-height: 1px;
			height: 740px;
		}
		@media screen and (max-width: $phone){
			height: 100dvh;	
      min-height: 640px;
		}
		&__bg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: scale(1.1);
			background: url(/images/index/fv_t2.jpg) no-repeat right top;
			background-size: cover;
			overflow: hidden;
			@media screen and (max-width: $tab){
				top: 0 !important;	
				left: 0 !important;	
				background: url(/images/index/fv_t2_sp.jpg) no-repeat center;
				background-size: cover;
			}
			&__ball{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url(/images/index/fv_ball.png) no-repeat center;
				background-size: cover;
				@media screen and (max-width: $tab){
					display: none;
				}
			}
		}
		&__title{
			position: absolute;
			top: calc(50% - 8px);
			left: 10vw;
			transform: translateY(-50%);
      z-index: 10;
			//mix-blend-mode: exclusion;
			@media screen and (max-width: $phone){
        width: calc(100%  - 60px);
				top: calc(50% - 28px);
				left: 30px;
			}
			dl{
				color: #FFF;
				@media screen and (max-width: $phone){
					text-shadow: 0 0 16px rgba(#000,.64);	
				}
				dt{
					//@include oswald();
					@include fontsize(26px, 8);
					font-weight: 700;
					line-height: 1.32;
					transform: translateX(-4px);
					@media screen and (max-width: $tab){
						@include fontsize(24px, 10);
					}
					@media screen and (max-width: $phone){
						@include fontsize(16px, 4);
						transform: translateX(-1px);
					}
				}
				dd{
					margin: 20px 0 0;
					font-size: 17.5px;
					line-height: 1.8;
					@media screen and (max-width: $tab){
						font-size: 1rem;	
					}
					@media screen and (max-width: $phone){
						font-size: .96rem;
						margin: 8px 0 0;
						.spnone{
							display: none;
						}
					}
					strong{
						color: $yellow;
						display: inline-block;
					}
					&.btn{
						margin: 16px 0 0;
						@media screen and (max-width: $tab){
						}
						a{
							font-size: 1.12rem;
							border: $yellow 1px solid;
							padding: 12px 56px;
							display: block;
							position: relative;
							transition: color 300ms $ease;
							@media screen and (max-width: $phone){
								font-size: 1rem;
								padding: 12px 15px;
								padding-right: 32px;
								display: block;
							}
							&:after{
								content: "\f061";
								@include awesame();
								font-size: 12px;
								position: absolute;
								right: 15px;
								top: 50%;
								transform: translateY(-50%);
							}
							&:hover{
								color: $white;
								&:before{
									width: 100%;
								}
							}
							&:before{
								content: '';
								width: 0;
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								background: $yellow;
								z-index: -1;
								transition: width 300ms $ease;
							}
						}
					}
				}
			}
		}
		&__copy,
		&__scroll{
			position: absolute;
			right: 48px;
			z-index: 10;
			color: $white;
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			font-size: .7rem;
			@media screen and (max-width: $tab){
				right: 30px;	
			}
			@media screen and (max-width: $phone){
				display: none;
			}
		}
		
		&__copy{
			top: 50%;
			transform: translateY(-50%);
			letter-spacing: .12em;
		}
		&__scroll{
			bottom: 0;
			padding: 0 0 64px;
			@media screen and (max-width: $tab){
				display: none;
			}
			&:before{
				$size: 48px;
				content: '';
				width: 1px;
				height: $size;
				background: $white;
				position: absolute;
				bottom: 0;
				left: 50%;
				animation: sc 1200ms $ease infinite;
				@keyframes sc{
					0%{
						height: $size;
						bottom: 0;
					}
					50%{
						height: 0;
						bottom: 0;
					}
					51%{
						height: 0;
						bottom: $size;
					}
					100%{
						height: $size;
						bottom: 0;
					}
				}
			}
		}
		&__bottom{
			padding: 16px 4vw;
			padding-right: 240px;
			background: #000;
			position: absolute;
			left: 0;
			bottom: 0;
			@include flex();
			justify-content: flex-start;
			color: $white;
      z-index: 10;
			border-radius: 0 8px 0 0;
			overflow: hidden;
			@media screen and (max-width: $phone){
				padding: 15px;
				width: 100%;
				display: block;
				border-radius: 0;
				z-index: 100;
			}
			&:before{
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 8px 0 8px 8px;
				border-color: transparent transparent transparent #000;
				right: 192px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				@media screen and (max-width: $phone){
					right: 40%;
					transform: translateY(-50%) translateX(100%);
				}
			}
			dl{
				position: relative;
				line-height: 1;
				@media screen and (max-width: $phone){
					width: calc(60% - 15px);	
				}
				&:first-of-type{
					padding: 0 50px 0 0;
					margin: 0 30px 0 0;
					@media screen and (max-width: $phone){
						padding: 0;
						margin: 0 0 12px;
					}
					&:after{
						content: '';
						width: 20px;
						height: 20px;
						background: url(/images/index/plus_icon_2x.png) no-repeat center center / 20px;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						@media screen and (max-width: $phone){
							display: none;
						}
					}
				}
				dt{
					color: $yellow;
					font-weight: 700;
					margin: 0 0 8px;
				}
				dd{
					font-size: 14px;
					@media screen and (max-width: $phone){
						font-size: .96rem;	
					}
				}
			}
			h1{
				position: absolute;
				top: 0;
				right: 0;
				width: 200px;
				height: 100%;
				background: $yellow;
				color: #FFF;
				@include flex();
				align-items: center;
				justify-content: center;
				font-size: 17px;
				line-height: 1.5;
				font-weight: 700;
				@media screen and (max-width: $phone){
					width: 40%;
					padding: 0 15px;
					font-size: 1.6rem;
					br{
						display: none;
					}
				}
			}
		}
		#viewdata{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 20;
			pointer-events: none;
			mix-blend-mode: overlay;
		}
	}
	
	
	.message{
		background: $black;
		color: $white;
		&__box{
			width: 700px;
			height: 240px;
			overflow: hidden;
			margin: 0 auto;
			font-size: 1.1rem;
			position: relative;
			@media screen and (max-width: $phone){
				width: auto;
				margin: 0 15px;
			}
			&.active{
				height: auto;
				overflow: auto;
				&:before{
					height: 0;
				}
			}
			&:before{
				content: '';
				width: 100%;
				height: 40%;
				position: absolute;
				left: 0;
				bottom: 0;
				background: -moz-linear-gradient(top,  rgba($black,0) 0%, rgba($black,1) 100%);
				background: -webkit-linear-gradient(top,  rgba($black,0) 0%,rgba($black,1) 100%);
				background: linear-gradient(to bottom,  rgba($black,0) 0%,rgba($black,1) 100%);
				z-index: 2;
			}
			&__p{
				margin: 0 0 32px;
				line-height: 2;
				text-align: justify;
				&:last-of-type{
					margin: 0;
				}
			}
			&__name{
				width: 110px;
				margin: 32px 0 0 auto;
				img{
					width: 100%;
				}
			}
		}
		.btnarea{
			a{
				i{
					display: inline-block;
					font-size: 1rem;
					margin: 0 0 0 8px;
				}
				&:after{
					display: none;
				}
			}
		}
	}
	.about{
		h3{
			font-size: 1.16rem;
			text-align: center;
			@media screen and (max-width: $phone){
				margin: 0 15px;
				br{
					display: none;
				}
			}
		}
		.context{
			margin: 32px 0 0;
			text-align: center;
			font-size: 1.56rem;
			font-weight: 700;
			line-height: 1.66;
			padding: 24px;
			border: $border 4px dotted;
			border-radius: 12px;
			@media screen and (max-width: $phone){
				padding: 15px;
				margin: 15px 0 0;
				font-size: 1.24rem;
				br{
					display: none;
				}
			}
			strong{
				display: inline-block;
				border-bottom: $yellow 4px solid;
			}
		}
		.onesec{
			margin: 40px 0 0;
            padding: 32px;
            background: $gray;
            border-radius: 8px;
            font-weight: 700;
            line-height: 1.56;
            text-align: center;
            @media screen and (max-width: $phone){
                padding: 15px;
                width: auto;
                margin: 15px 0 0;
            }
            dt{
                font-size: 1.88rem;
                @media screen and (max-width: $tab){
                    font-size: 1.56rem;	
                }
            }
            dd{
                font-size: 1.64rem;
				@media screen and (max-width: $tab){
					font-size: 1.32rem;
				}
                strong{
                    color: $yellow;
                    font-size: 3.2rem;
                    line-height: 1;
					@media screen and (max-width: $phone){
						font-size: 2rem;	 
					}
                }
            }
		}
		p.onesec{
			font-size: 1.8rem;
			font-weight: 700;
			color: darken($yellow, 6%);
			strong{
				color: #f91e1e;
				font-size: 3.2rem;
        line-height: 1.5;
        @media screen and (max-width: $tab){
          font-size: 2.4rem;
        }
        @media screen and (max-width: $phone){
          font-size: 1.66rem;
        }
			}
			@media screen and (max-width: $phone){
				font-size: 1.4rem;	
			}
		}
		ul.twosec{
			@include flex();
			margin: 40px 0 0;
			background: url(/images/index/plus_icon_2x.png) no-repeat center / 45px;
			@media screen and (max-width: $phone){
				background: none;
				display: block;
				margin: 24px 0 0;
				background: none;
			}
			li{
				width: calc(50% - 48px);
				padding: 32px;
				background: $gray;
				border-radius: 8px;
				@include flex();
				justify-content: center;
				align-items: center;
				overflow: hidden;
				position: relative;
				@media screen and (max-width: $phone){
					padding: 15px 24px;
					width: auto;
					margin: 0 0 15px;
					&:after{
						margin: 0;
					}
				}
				&:before,
				&:after{
					content: '';
					position: absolute;
					z-index: 2;
				}
				&:before{
					width: 112px;
					height: 112px;
					top: 0;
					left: 0;
					background: $yellow;
					transform: translate(-50%, -50%) rotate(-45deg);
					@media screen and (max-width: $tab){
						width: 96px;
						height: 96px;
					}
					@media screen and (max-width: $phone){
						width: 72px;
						height: 72px;
					}
				}
				&:after{
					content: attr(data-eng);
					@include oswald();
					font-size: 1.72rem;
					color: #FFF;
					position: absolute;
					top: 0;
					left: 10px;
					@media screen and (max-width: $tab){
						font-size: 1.48rem;	
					}
					@media screen and (max-width: $phone){
						font-size: 1.12rem;
						left: 8px;
					}
				}
				dl{
					font-weight: 700;
					line-height: 1.56;
					text-align: center;
					dt{
						font-size: 1.72rem;
						@media screen and (max-width: $tab){
							font-size: 1.56rem;	
						}
						&.mid{
							font-size: 1.32rem;
							@media screen and (max-width: $tab){
								font-size: 1.24rem;	
							}
							@media screen and (max-width: $phone){
								br{
									display: none;
								}
							}
						}
					}
					dd{
						font-size: 1.46rem;
						strong{
							color: $yellow;
							font-size: 2.1rem;
							line-height: 1;
						}
						&.mid{
							font-size: 1.56rem;
						}
					}
				}
			}
		}
		.btnarea{
			margin-top: 40px;
			@media screen and (max-width: $phone){
				margin-top: 24px;	
			}
		}
	}
	
	.voice{
		h2{
			font-size: 2em;
			font-weight: 700;
			color: #FFF;
			background: $yellow;
			padding: 12px 0;
			text-align: center;
			margin: 0 0 56px;
			position: relative;
			@media screen and (max-width: $phone){
				margin: 0 0 32px;
				font-size: 1.48rem;
			}
			span{
				position: relative;
				display: inline-block;
				&:before{
					content: '';
					width: 6px;
					height: 6px;
					background: #FFF;
					position: absolute;
					top: 0;
					left: 50%;
					border-radius: 50%;
					transform: translate(-50%);
				}
			}
			&:before{
				content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 24px 10px 0 10px;
                border-color: $yellow transparent transparent transparent;
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translate(-50%, 100%);
			}
		}
		.voicelist{
			&__item{
				background: $normal;
				@include flex();
				align-items: center;
				margin: 0 0 40px;
				@media screen and (max-width: $tab){
					padding: 20px;
					margin: 0 0 20px;
				}
				@media screen and (max-width: $phone){
					padding: 15px;
					display: block;
					margin: 0 0 24px;
				}
				&:last-child{
					margin: 0;
				}
				&__pic{
					width: 450px;
					position: relative;
					@media screen and (max-width: $tab){
						width: 320px;	
					}
					@media screen and (max-width: $phone){
						width: auto;	
					}
					img{
						width: 100%;
					}
				}
				&__data{
					width: calc(100% - 450px);
					padding: 26px 0;
					padding-left: 40px;
					padding-right: 32px;
					font-size: 1.12rem;
					color: #FFF;
					@media screen and (max-width: $tab){
						width: calc(100% - 320px);
						padding: 0;
						padding-left: 24px;
						padding-right: 0;
					}
					@media screen and (max-width: $phone){
						width: auto;
						padding: 0;
						margin: 15px 0 0;
					}
					ol{
						@include flex();
						align-items: center;
						justify-content: flex-start;
						li{
							margin: 0 15px 0 0;
							padding: 0 24px 0 0;
							position: relative;
							@media screen and (max-width: $tab){
								font-size: .88rem;	
							}
							&:last-child{
								margin: 0;
								padding: 0;
								&:after{
									display: none;
								}
							}
							&:after{
								content: '\f054';
								@include awesame();
								position: absolute;
								top: 50%;
								right: 0;
								transform: translateY(-50%);
								font-size: .72rem;
							}
						}
					}
					dl{
						margin: 8px 0 0;
						@include flex();
						align-items: center;
						justify-content: flex-start;
						dt{
							font-weight: 700;
							strong{
								font-size: 2.2rem;
								display: inline-block;
								margin: 0 8px 0 0;
								@media screen and (max-width: $tab){
									font-size: 1.64rem;	
								}
							}
						}
						dd{
							border: #FFF 1px solid;
							width: 150px;
							padding: 5px 0;
							text-align: center;
							margin: 0 20px 0 0;
							@media screen and (max-width: $tab){
								width: 120px;
								font-size: 1rem;
								padding: 2px 0;
							}
						}
					}
					.btn{
						border-top: rgba(#FFF,.08) 1px solid;
						padding: 16px 0 0;
						margin: 10px 0 0;
						a{
							background: $yellow;
							border: $yellow 1px solid;
							color: #FFF;
							display: inline-block;
							padding: 12px 40px;
							font-size: 1.32rem;
							font-weight: 700;
							@media screen and (max-width: $phone){
								display: block;
								padding: 12px 0;
								text-align: center;
							}
							i{
								display: inline-block;
								transform: rotateY(180deg);
								margin: 0 8px 0 0;
							}
                            &:hover{
                                background: none;
                                color: $yellow;
                            }
						}
					}
				}
				&__hide{
					background: $gray;
					padding: 40px;
					width: 100%;
					display: none;
					@media screen and (max-width: $tab){
						padding: 20px;
						margin: 20px 0 0;
					}
					@media screen and (max-width: $phone){
						padding: 15px;
						margin: 15px 0 0;
					}
					ul{
						padding: 40px;
						background: #FFF;
						@media screen and (max-width: $tab){
							padding: 20px;
						}
						@media screen and (max-width: $phone){
							padding: 15px;	
						}
						li{
							margin: 0 0 40px;
							padding: 0 0 40px;
							border-bottom: $border 1px solid;
							@media screen and (max-width: $tab){
								margin: 0 0 20px;
								padding: 0 0 20px;
							}
							@media screen and (max-width: $phone){
								margin: 0 0 15px;
								padding: 0 0 15px;
							}
							&:last-child{
								margin: 0;
								padding: 0;
								border: none;
							}
							dl{
								dt{
									font-size: 24px;
									color: $yellow;
									font-weight: 700;
									margin: 0 0 8px;
									@media screen and (max-width: $tab){
										font-size: 1.32rem;	
									}
									@media screen and (max-width: $phone){
										line-height: 1.56;	
									}
								}
								dd{
									font-size: 15px;
									text-align: justify;
									@media screen and (max-width: $tab){
										font-size: 1rem;	
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.case{
		background: $black url(/images/index/case_bg_2x.jpg) no-repeat top center / 100%;
		h2.large{
			color: $white;
		}
		&__list{
			&__item{
				box-shadow: 0 0 32px rgba(#000,.12);
				margin: 0 0 40px;
				@media screen and (max-width: $tab){
					box-shadow: 0 0 20px rgba(#000,.12);
					margin: 0 0 20px;
				}
				@media screen and (max-width: $phone){
					margin: 0 0 15px;	
				}
				&:last-child{
					margin: 0;
				}
				&__box{
					background: $normal;
					padding: 24px 32px;
					color: #FFF;
					position: relative;
					@media screen and (max-width: $tab){
						padding: 20px;	
					}
					.pic{
						position: absolute;
						top: 0;
						right: 0;
						width: 520px;
						height: 100%;
						overflow: hidden;
						@media screen and (max-width: $tab){
							position: relative;
							width: 100%;
							height: 0;
							padding-top: 56%;
							margin: 0 0 20px;
						}
						img{
							position: absolute;
							top: 50%;
							left: 50%;
							height: 100%;
							transform: translate(-50%, -50%);
							@media screen and (max-width: $tab){
								width: 100%;
								height: auto;
							}
						}
						&:before,
						&:after{
							content: '';
							width: 160px;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background: linear-gradient(to right,  rgba($normal,1) 30%,rgba($normal,0) 100%);
							z-index: 2;
							@media screen and (max-width: $tab){
								display: none;
							}
						}
					}
					.name{
						@include flex();
						align-items: flex-end;
						justify-content: flex-start;
						font-weight: 700;
						line-height: 1;
						padding: 0 0 20px;
						margin: 0 0 20px;
						border-bottom: rgba(#FFF,.08) 1px solid;
						dd{
							font-size: 1.44rem;
							margin: 0 16px 0 0;
							@media screen and (max-width: $phone){
								font-size: 1.12rem;	
							}
						}
						dt{
							font-size: 2.5rem;
							@media screen and (max-width: $phone){
								font-size: 1.56rem;	
							}
						}
					}
					.rank{
						@include flex();
						justify-content: flex-start;
						position: relative;
						z-index: 2;
						@media screen and (max-width: $tab){
							justify-content: space-between;
						}
						@media screen and (max-width: $phone){
							display: block;	
						}
						li{
							margin: 0 16px 0 0;
							padding: 4px;
							min-width: 188px;
							background: rgb(175,137,5);
							background: -moz-linear-gradient(-45deg,  rgba(175,137,5,1) 0%, rgba(226,200,108,1) 50%, rgba(175,137,5,1) 100%);
							background: -webkit-linear-gradient(-45deg,  rgba(175,137,5,1) 0%,rgba(226,200,108,1) 50%,rgba(175,137,5,1) 100%);
							background: linear-gradient(135deg,  rgba(175,137,5,1) 0%,rgba(226,200,108,1) 50%,rgba(175,137,5,1) 100%);
							@media screen and (max-width: $tab){
								min-width: 1px;
								width: 32%;
								margin: 0;
							}
							@media screen and (max-width: $phone){
								width: auto;
								margin: 0 0 8px;
							}
							&:last-child{
								margin: 0;
							}
							dl{
								background: rgb(27,27,27);
								background: -moz-linear-gradient(top,  rgba(27,27,27,1) 0%, rgba(0,0,0,1) 100%);
								background: -webkit-linear-gradient(top,  rgba(27,27,27,1) 0%,rgba(0,0,0,1) 100%);
								background: linear-gradient(to bottom,  rgba(27,27,27,1) 0%,rgba(0,0,0,1) 100%);
								padding: 12px;
								line-height: 1.56;
								text-align: center;
								@media screen and (max-width: $phone){
									@include flex();
									align-items: center;
								}
								dt{
									font-size: 1.2rem;
									padding: 0 0 24px;
									position: relative;
									@media screen and (max-width: $phone){
										width: calc(60% - 15px);
										padding: 0;
										height: auto;
										text-align: left;
									}
									&:after{
										content: '';
										width: 100%;
										height: 1px;
										background: $yellow;
										position: absolute;
										bottom: 12px;
										left: 0;
										border-radius: 50%;
										@media screen and (max-width: $phone){
											display: none;
										}
									}
								}
								dd{
									font-size: 1.44rem;
									color: $yellow;
									@media screen and (max-width: $phone){
										width: 40%;
										padding: 0 0 0 15px;
										border-left: rgba($yellow,.24) 1px solid;
									}
									&:before{
										content: attr(data-text);
										display: block;
										font-size: 1.2rem;
									}
									strong{
										@include oswald();
										font-size: 5rem;
										display: inline-block;
										margin: 0 4px;
										line-height: 1;
										@media screen and (max-width: $phone){
											font-size: 3.2rem;	
										}
									}
								}
							}
						}
					}
				}
				.more{
					background: $yellow;
					display: block;
					color: #FFF;
					font-size: 1.4rem;
					padding: 8px 0;
					text-align: center;
					&:hover{
						background: lighten($yellow, 8%);
					}
					i{
						display: inline-block;
						margin: 0 0 0 8px;
						font-size: 1.1rem;
					}
				}
				&__hidden{
					background: #FFF;
					border: $normal 40px solid;
					padding: 40px;
					display: none;
					@media screen and (max-width: $tab){
						border-width: 20px;
						padding: 20px;
					}
					&__p{
						border-bottom: $border 1px solid;
						padding: 0 0 40px;
						margin: 0 0 40px;
						font-size: 1.08em;
						&:last-of-type{
							margin-bottom: 0;
							padding-bottom: 0;
							border: none;
						}
					}
					.video{
						@include flex();
						margin: 40px 0 0;
						padding: 40px 0 0;
						border-top: 1px solid $border;
						li{
							width: 32%;
							iframe{
								width: 100%;
								height: 170px;
								vertical-align: bottom;
							}
						}
					}
				}
			}
		}
	}
	.greet{
		@include flex();
		align-items: flex-start;
		margin: 0 0 64px;
		&.nobottom{
			margin-bottom: 0;
		}
		@media screen and (max-width: $tab){
			display: block;	
		}
		@media screen and (max-width: $phone){
			margin: 0 0 32px;
		}
		&__left{
			position: sticky;
			top: 64px;
			width: 240px;
			@media screen and (max-width: $tab){
        top: 0;
				position: relative;
				width: auto;
				@include flex();
			}
			@media screen and (max-width: $phone){
				display: block;	
			}
			figure{
				@media screen and (max-width: $tab){
					width: calc(30% - 20px);
					height: 280px;
					position: relative;
					overflow: hidden;
				}
				@media screen and (max-width: $phone){
					background: $gray;
					text-align: center;
					width: auto;
				}
			}
			img{
				width: 100%;
				@media screen and (max-width: $tab){
					object-fit: cover;
					object-position: center;
					width: 100%;
					height: 100%;
				}
				@media screen and (max-width: $phone){
					width: auto;
					object-fit: contain;
				}
			}
			.video{
				position: relative;
				padding-top: 56%;
				margin: 24px 0 0;
				@media screen and (max-width: $tab){
					width: 70%;
					padding: 0;
					margin: 0;
				}
				@media screen and (max-width: $phone){
					margin: 24px 0 0;
					width: 100%;
				}
				iframe{
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					vertical-align: bottom;
					@media screen and (max-width: $tab){
						position: relative;
						height: 280px;	
					}
					@media screen and (max-width: $phone){
						height: 200px;	
					}
				}
			}
			.playlist{
				margin: 16px 0 0;
				@media screen and (max-width: $tab){
					width: 100%;	
				}
				a{
					display: block;
					border: $yellow 2px solid;
					color: $yellow;
					font-weight: 700;
					border-radius: 2px;
					padding: 8px 0;
					text-align: center;
					i{
						display: inline-block;
						margin: 0 8px 0 0;
					}
				}
			}
		}
		&__right{
			width: calc(100% - 240px);
			padding: 0 0 0 48px;
			@include flex();
			@media screen and (max-width: $tab){
				width: 100%;
				padding: 0;
				margin: 32px 0 0;
			}
			@media screen and (max-width: $phone){
				display: block;
				margin: 24px 0 0;
			}
			&__box{
				width: calc(50% - 16px);
				font-size: 1.12rem;
				@media screen and (max-width: $phone){
					width: auto;
					margin: 0 0 24px;
				}
				h3{
					border-bottom: $black 2px solid;
					margin: 0 0 16px;
					strong{
						background: $black;
						color: $white;
						font-size: 1rem;
						font-weight: 700;
						padding: 1px 18px;
						width: 170px;
						display: inline-block;
						text-align: center;
						margin: 0 16px 0 0;
					}
					&:after{
						@include oswald;
						content: attr(data-eng);
					}
				}
				dl{
					dt{
						font-size: .8rem;
						margin: 0 0 8px;
					}
					dd{
						padding: 0 0 0 36px;
						margin: 0 0 4px;
						background: url(/images/index/greet_arrow.png) no-repeat center left / 12px;
						&:last-child{
							margin: 0;
						}
					}
				}
				ul{
					li{
						margin: 0 0 4px;
						&:last-child{
							margin: 0;
						}
					}
				}
				&__price{
					width: 100%;
					padding: 16px;
					margin: 0 0 24px;
					background: $gray;
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 2.4rem;
          @media screen and (max-width: $phone){
            grid-template-columns: repeat(1, 1fr);
            gap: 1rem;  
          }
					dl{
						display: grid;
						grid-template-columns: 7.2rem 1fr;
						gap: 1.2rem;
						> *{
							align-self: center;
						}
						dt{
							background: $yellow;
							text-align: center;
							padding: .24rem .8rem;
							color: #FFF;
						}
						dd{
							font-size: 1.56rem;
							font-weight: 700;
							line-height: 1;
						}
					}
				}
			}
			&__aww{
				margin: 32px 0 0;
				background: $gray;
				padding: 32px;
				width: 100%;
				@include flex();
				border-radius: 3px;
				&.grid{
					background: none;
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					gap: 1.2rem;
					padding: 0;
					padding-bottom: 32px;
          @media screen and (max-width: $phone){
            gap: .4rem;
            grid-template-columns: 1fr;
          }
					> p{
						background: $gray;
						padding: 1rem;
						text-align: center;
            @media screen and (max-width: $phone){
              padding: .64rem 1.6rem;
              text-align: left;
            }
					}
				}
				@media screen and (max-width: $phone){
					padding: 15px;
					margin: 24px 0 0;
					display: block;
				}
				h3{
					font-size: 1.56rem;
					font-weight: 700;
					text-align: center;
					width: 100%;
					margin: 0 0 20px;
				}
				ul{
					width: calc(50% - 15px);
					@media screen and (max-width: $phone){
						width: auto;	
					}
					li{
						padding: 0 0 0 16px;
						position: relative;
						margin: 0 0 3px;
						@media screen and (max-width: $phone){
							margin: 0 0 8px !important;
							font-size: 1.08rem;
						}
						&:last-child{
							margin: 0;
							padding-bottom: 0;
							border: none;
						}
						&:before{
							content: '';
							width: 8px;
							height: 8px;
							border-radius: 50%;
							background: $yellow;
							position: absolute;
							top: 10px;
							left: 0;
						}
					}
				}
			}
		}
		&__acc{
			@include flex();
			@media screen and (max-width: $tab){
				display: block;	
			}
			figure{
				width: 300px;
				@media screen and (max-width: $tab){
					width: auto;
					margin: 0 0 24px;
				}
				img{
					width: 100%;
				}
			}
			ul{
				background: $gray;
				padding: 24px;
				width: calc(100% - 300px);
				@media screen and (max-width: $tab){
					width: auto;	
				}
				@media screen and (max-width: $phone){
					padding: 15px;	
				}
				li{
					font-size: 1.1rem;
					background: #FFF;
					padding: 8px 16px;
					padding-left: 40px;
					margin: 0 0 8px;
					position: relative;
					&:last-child{
						margin: 0;
					}
                    &:before{
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-radius: 2px;
                        background: $yellow;
                        position: absolute;
                        top: 50%;
                        left: 15px;
						transform: translateY(-50%);
                    }
				}
			}
		}
	}
	#diff{
		@media screen and (max-width: $tab){
			padding: 0 30px 64px;	
		}
		@media screen and (max-width: $phone){
			padding: 0 15px 32px;	
		}
	}
	.pricedata{
		@include flex();
		margin: 0 0 40px;
		@media screen and (max-width: $phone){
			display: block;
			margin: 0 0 32px;	
		}
		li{
			width: calc(33.3% - 12px);
			padding: 20px 24px;
			border-radius: 6px;
			color: #FFF;
			position: relative;
			@media screen and (max-width: $tab){
				width: calc(33.3% - 6px);
				padding: 20px;
			}
			@media screen and (max-width: $phone){
				width: auto;
				padding: 15px;
				margin: 0 0 15px;
				&:last-child{
					margin: 0;
				}
			}
			&:before{
				content: '';
				width: calc(100% - 8px);
				height: calc(100% - 8px);
				position: absolute;
				top: 4px;
				left: 4px;
				border: #FFF 1px solid;
				border-radius: 4px;
			}
			&:nth-child(1){
				background: #3c6803;
			}
			&:nth-child(2){
				background: #5a8907;
			}
			&:nth-child(3){
				background: #cc5277;
			}
			dl{
				text-align: center;
				dt{
					font-size: 1.8rem;
					font-weight: 700;
					line-height: 1;
					padding: 0 0 20px;
					margin: 0 0 20px;
					border-bottom: rgba(#FFF,.24) 1px solid;
					@media screen and (max-width: $tab){
						font-size: 1.44rem;	
					}
					@media screen and (max-width: $phone){
						padding: 0 0 12px;
						margin: 0 0 12px;
					}
				}
				dd{
					font-size: 1.12rem;
					@media screen and (max-width: $tab){
						font-size: 1rem;	
					}
					&.price{
						font-weight: 700;
						font-size: 1.8rem;
						margin: 4px 0 0;
						@media screen and (max-width: $tab){
							font-size: 1.44rem;
						}
						strong{
							font-size: 3.2rem;
							line-height: 1;
							color: lighten($yellow, 20%);
							@media screen and (max-width: $tab){
								font-size: 2rem;	
							}
						}
					}
				}
			}
		}
	}
	.pricelist{
		@include flex();
		margin-left: -15px;
		margin-bottom: 32px;
		@media screen and (max-width: $tab){
			justify-content: flex-start;	
		}
		@media screen and (max-width: $phone){
			margin: 0 0 24px -15px;
		}
		li{
			width: 20%;
			padding: 0 0 0 15px;
			@media screen and (max-width: $tab){
				width: 33.3%;
				margin: 15px 0 0;
				&:nth-child(-n+3){
					margin-top: 0;
				}
			}
			@media screen and (max-width: $phone){
				width: 50%;
				margin: 0 0 12px;
			}
			img{
				border-radius: 4px;
				width: 100%;
			}
			.alert{
				background: #FFF;
				border: $yellow 2px solid;
				border-radius: 4px;
				padding: 8px 15px;
				font-size: .74rem;
				line-height: 1.5;
				text-align: justify;
				margin: 20px 0 0;
				position: relative;
				@media screen and (max-width: $phone){
					font-size: .88rem;
					width: calc(100vw - 30px);
				}
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 8px 12px 8px;
					border-color: transparent transparent $yellow transparent;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%, -100%);
					@media screen and (max-width: $phone){
						left: 25%;	
					}
				}
			}
		}
	}
	.pricevtc{
		background: $gray;
		border-radius: 3px;
		@include flex();
		align-items: center;
		padding: 24px 40px;
		font-size: 1.24rem;
		font-weight: 700;
		@media screen and (max-width: $phone){
			padding: 15px;
			display: block;
		}
		dd{
			@media screen and (max-width: $phone){
				margin: 8px 0 0;	
			}
			strong{
				//@include oswald();
				font-size: 2rem;
				line-height: 1;
				font-weight: 700;
			}
			&:before{
				content: attr(data-txt);
				background: $yellow;
				color: #FFF;
				font-size: .96rem;
				line-height: 1;
				padding: 8px 16px;
				border-radius: 3px;
				margin: 0 12px 0 0;
				display: inline-block;
				transform: translateY(-4px);
			}
		}
	}
	
	.safety{
		background: $black url(/images/index/kussion_dark.jpg) no-repeat right bottom / contain;
		padding: 88px 0;
		color: #FFF;
		text-align: center;
		@media screen and (max-width: $tab){
			padding-left: 30px;
			padding-right: 30px;
		}
		@media screen and (max-width: $phone){
			padding: 32px 15px;	
		}
		.kussion{
			&__str{
				font-size: 1.32rem;
				font-weight: 700;
				margin: 0 0 8px;
			}
			&__ttl{
				font-size: 2.2rem;
				font-weight: 700;
				line-height: 1;
				@media screen and (max-width: $phone){
					font-size: 1.66rem;	
				}
				strong{
					font-size: 4rem;
					@media screen and (max-width: $phone){
						font-size: 2.2rem;	
					}
					&.yel{
						color: $yellow;
					}
				}
			}
			&__border{
				border: #FFF 1px solid;
				display: inline-block;
				font-size: 1.12rem;
				font-weight: 700;
				padding: 6px 32px;
				margin: 24px 0 0;
			}
			&__atten{
				margin: 20px 0 0;
				text-align: center;
				font-size: .88rem;
			}
			.btnarea{
				margin-top: 12px;
			}
		}
	}
	
	// Add 0222
	.trouble{
		&__list{
			@include flex();
			@media screen and (max-width: $phone){
				display: block;	
			}
			li{
				width: calc(33.3% - 10px);
				padding: 20px 24px;
				padding-left: 52px;
				font-size: 1.12rem;
				line-height: 1.6;
				margin: 0 0 20px;
				background: $gray;
				border-radius: 4px;
				position: relative;
				text-align: left;
				&:before{
					content: "\f00c";
					font-weight: 900;
					font-family: "Font Awesome 5 Free";
					color: $yellow;
					display: inline-block;
					position: absolute;
					top: 20px;
					left: 24px;
					@media screen and (max-width: $phone){
						left: 15px;	
					}
				}
				@media screen and (max-width: $phone){
					width: auto;
					padding: 15px;
					padding-left: 40px;
					margin: 0 0 8px;
				}
			}
		}
		&__context{
			margin: 32px 0 0;
			text-align: center;
			font-size: 1.56rem;
			font-weight: 700;
			line-height: 1.66;
			padding: 24px;
			border: $border 4px dotted;
			border-radius: 12px;
			@media screen and (max-width: $phone){
				padding: 15px;
				margin: 15px 0 0;
				font-size: 1.24rem;
				br{
					display: none;
				}
			}
			strong{
				display: inline-block;
				border-bottom: $yellow 4px solid;
			}
		}
	}
	
	.user{
		background: $gray;
		&__voice{
			li{
				margin: 0 0 32px;
                background: #FFF;
                box-shadow: 0 0 24px rgba($normal, .04);
				padding: 32px;
				@include flex();
				align-items: flex-start;
				position: relative;
				@media screen and (max-width: $phone){
					padding: 20px;
					margin: 0 0 16px;
					box-shadow: 0 0 15px rgba($normal, .04);
					display: block;
				}
				&:last-child{
					margin: 0;
				}
				.icon{
					width: 56px;
					height: 56px;
					border-radius: 50%;
					background: $gray;
					color: $yellow;
					@include flex();
					align-items: center;
					justify-content: center;
					@media screen and (max-width: $phone){
						position: absolute;
						top: 20px;
						left: 20px;
						width: 32px;
						height: 32px;
					}
					i{
						font-size: 24px;
						@media screen and (max-width: $phone){
							font-size: 14px;	
						}
					}
				}
				.data{
					width: calc(100% - 56px);
					padding: 0 0 0 32px;
					@media screen and (max-width: $phone){
						width: auto;
						padding: 0;
					}
					>dt{
						font-size: 1.32rem;
						font-weight: 700;
						margin: 0 0 16px;
						padding: 0 0 12px;
						border-bottom: $border 1px solid;
						@media screen and (max-width: $phone){
							padding-left: 44px;	
						}
						strong{
							display: inline-block;
							margin: 0 8px 0 0;
						}
					}
					>dd{
						font-size: 1.04rem;
						line-height: 1.8;
						text-align: justify;
						@media screen and (max-width: $phone){
							font-size: .96rem;	
						}
						> dl{
							margin: 0 0 20px;
							&:last-of-type{
								margin: 0;
							}
							>dt{
								font-weight: 700;
								margin: 0 0 3px;
								&:before{
									content: '■';
									color: $yellow;
									display: inline-block;
									margin: 0 6px 0 0;
								}
							}
							>dd{
								
							}
						}
					}
				}
			}
		}
	}
	.photolist{
		width: 100%;
		overflow: hidden;
		margin: 0 0 32px;
		&__grid{
			margin-left: -5px;
			width: calc(100% + 10px);
			.grid-item{
				width: 50%;
				padding: 5px;
				img{
					width: 100%;
					border-radius: 6px;
				}
			}
		}
	}
	.longmenu{
		margin: 0 0 64px;
		&__item{
			background: $gray;
			padding: 24px 40px;
			border-radius: 3px;
			overflow: hidden;
			margin: 0 0 40px;
			position: relative;
			@media screen and (max-width: $tab){
				padding: 24px;
				margin: 0 0 32px;
			}
			@media screen and (max-width: $phone){
				padding: 16px;
				margin: 0 0 24px;
			}
			&:last-child{
				margin: 0;
			}
			dl{
				position: relative;
				font-size: 1.88rem;
				line-height: 1.56;
				font-weight: 700;
				padding: 0 160px 16px 0;
				margin: 0 0 20px;
				border-bottom: #FFF 4px solid;
				@media screen and (max-width: $tab){
					font-size: 1.56rem;
				}
				@media screen and (max-width: $phone){
					font-size: 1.32rem;
					padding: 0 0 16px;
				}
                small{
                    font-size: 1.32rem;
                    display: inline-block;
                    margin: 0 6px;
					@media screen and (max-width: $tab){
						font-size: 1rem;
					}
					@media screen and (max-width: $phone){
						margin: 0 4px 0 0;
					}
                }
				dd{
					position: absolute;
					top: 0;
					right: 0;
					@media screen and (max-width: $phone){
						position: relative;
						right: auto;
						left: 0;
						font-size: 1.56rem;
					}
				}
			}
			p{
				&.mb{
					margin-bottom: 20px;
					@media screen and (max-width: $phone){
						margin-bottom: 16px;	
					}
				}
				&.str{
					padding: 15px;
					background: #FFF;
					font-weight: 700;
				}
			}
			&__list{
				@include flex();
				@media screen and (max-width: $tab){
					display: block;
				}
				li{
					font-size: 1.12rem;
					margin: 0 0 4px;
					width: calc(50% - 24px);
					padding: 0 0 0 28px;
					position: relative;
					@media screen and (max-width: $tab){
						width: auto;
						font-size: 1.08rem;
						margin: 0 0 6px;
						&:last-child{
							margin: 0;
						}
					}
					&:before{
						@include awesame();
						content: '\f058';
						display: inline-block;
						margin: 0 10px 0 0;
						color: $yellow;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
}