#site-header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: $pc-head;
	z-index: 1000;
	padding: 0 48px;
	@include flex();
	align-items: center;
	@media screen and (max-width: $tab){
		padding: 0 30px;	
	}
	@media screen and (max-width: $phone){
		padding: 0 15px;
		height: $sp-head;
	}
	&.active{
		position: fixed;
		background: $black;
		height: 72px;
		transform: translateY(-100%);
		animation: viewhead 600ms $ease forwards;
		@media screen and (max-width: $phone){
			height: $sp-head;
		}
		@keyframes viewhead {
			0%{
				transform: translateY(-100%);
			}
			100%{
				transform: translateY(0);
			}
		}
	}
	.logo{
		height: 24px;
		&.c{
			height: 36px;
			transform: translateY(-2px);
			@media screen and (max-width: $phone){
				height: 24px;	
			}
		}
		@media screen and (max-width: $phone){
			height: 20px;
			transform: translateY(-3px);
		}
		img{
			height: 100%;
		}
	}
	.contact{
		display: block;
		border: $yellow 1px solid;
		padding: 8px 40px;
		position: relative;
		transition: color 200ms $ease;
		@media screen and (max-width: $phone){
			padding: 4px 12px;
			font-size: .96rem;
		}
		&.white{
			border-color: #FFF;
			color: #FFF;
			&:before{
				background: #FFF;
			}
			&:hover{
				color: $normal
			}
		}
		&:hover{
			color: $white;
			&:before{
				width: 100%;
			}
		}
		&:before{
			content: '';
			width: 0;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $yellow;
			z-index: -1;
			transition: width 200ms $ease;
		}
	}
	.gnavi{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@media screen and (max-width: $tab){
			display: none;
		}
		ul{
			@include flex();
			align-items: center;
			justify-content: center;
			li{
				font-size: 1.07rem;
				margin: 0 3vw 0 0;
				&:last-child{
					margin: 0;
				}
				a{
					color: $white;
					display: block;
					position: relative;
					&:before{
						content: '';
						width: 0;
						height: 1px;
						background: -moz-radial-gradient(center, ellipse cover,  rgba(lighten($yellow, 12%), 1) 0%, rgba($yellow,0) 100%);
						background: -webkit-radial-gradient(center, ellipse cover,  rgba(lighten($yellow, 12%), 1) 0%,rgba($yellow,0) 100%);
						background: radial-gradient(ellipse at center,  rgba(lighten($yellow, 12%), 1) 0%,rgba($yellow,0) 100%);
						position: absolute;
						left: 50%;
						bottom: -4px;
						border-radius: 50%;
						transform: translateX(-50%);
						transition: width 180ms $ease;
					}
					&:hover{
						color: $yellow;
						&:before{
							width: 80px;
						}
					}
				}
			}
		}
	}
}